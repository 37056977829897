import React from "react"
import { graphql } from "gatsby"

import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PricingPage = props => {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <SEO title={t(`pricing`)} />
      <section className="section has-bg-craft" id="">
        <div className="container">
          <div className="content">
            <h1>{t(`pricing`)}</h1>
            {(i18n.language === "ja-gb" && <p>イギリス国内向け</p>) ||
              (i18n.language === "ja-jp" && <p>日本国内向け</p>)}
            <table className="table is-bordered is-hoverable is-fullwidth">
              <thead>
                <tr className="is-selected">
                  <th style={{ width: "33%" }}></th>
                  <th style={{ width: "33%" }}>{t(`payg`)}</th>
                  <th style={{ width: "33%" }}>{t(`professional`)}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t(`setup_fee`)}</td>
                  <td>£100</td>
                  <td rowSpan="3">{t(`professional_contact`)}</td>
                </tr>
                <tr>
                  <td>{t(`monthly_fee`)}</td>
                  <td>
                    <strong>{t(`free`)}</strong>/{t(`mo`)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(`order_fee`)}
                    <br />
                    <small>{t(`per_transaction`)}</small>
                  </td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>
                    {t(`card_fee`)}
                    <br />
                    <small>{t(`per_transaction`)}</small>
                  </td>
                  {(i18n.language === "en-gb" && <td>1.4% + 20p *</td>) ||
                    (i18n.language === "ja-gb" && <td>1.4% + 20p *</td>) ||
                    (i18n.language === "ja-jp" && <td>3.6%</td>)}
                  {(i18n.language === "en-gb" && <td>1.4% + 20p *</td>) ||
                    (i18n.language === "ja-gb" && <td>1.4% + 20p *</td>) ||
                    (i18n.language === "ja-jp" && <td>3.6%</td>)}
                </tr>
              </tbody>
            </table>
            {(i18n.language === "en-gb" || i18n.language === "ja-gb") && (
              <p>* 2.9% + 20p for non-European cards.</p>
            )}
          </div>

          <div className="columns is-desktop is-centered">
            <div className="column is-6-desktop">
              <a
                className="button is-yellow is-large is-fullwidth"
                href="https://docs.google.com/forms/d/e/1FAIpQLScwEvFeQ_0a3xPUN4X2x45rEV32O-QXKyPVAt391baJBrT5Eg/viewform?usp=sf_link"
              >
                {t(`start_free_trial`)}
              </a>
              <p className="has-text-centered" style={{ marginTop: "16px" }}>
                <Link to="/contact">{t(`contact_us`)}</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PricingPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
